import React, { useEffect, useState } from "react";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import { IoSync } from "react-icons/io5";
import PostApiCall from "../../../Api";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
// import "./olympic.css";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import './leadelanenquiry.css'
const LeadElanEnquiry = () => {
	const [mailtype, setType] = useState("");
	const [clientid, setClientId] = useState("");
	const mailtypefetchurl = useParams();
	const [Rotatecaptcha, setRotatecaptcha] = useState(false);
	// state for loader
	const [Loader, setLoader] = useState(false);
	const [Enquiry, setEnquiry] = useState({
		name: "",
		email: "",
		mobile: "",
		value: "",
		code: "",
		message: "",
		subject: "",
		companyname: "",
		city: "",
		country: "",
		captcha: "",
		EmailRegex:
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		NumRegex: /^[0-9]*$/,
	});
	function formateHtml() {
		return (
			'<html><head><meta charset="utf-8"><title>Sresan | Enquiry</title></head>' +
			'<body style="font-family: sans-serif">' +
			'<div style="width: 100%; margin: auto;background-color: #8080800d;">' +
			'<div style="text-align: left; margin-bottom: 10px;padding: 15px 15px 0;">' +
			'<img style="width: 200px" src="https://www.TyreNTyre.com/images/main-logo.jpeg"/>' +
			"</div>" +
			'<div style="padding:1px 20px 0">' +
			'<h2 style="text-align: left;font-size:14px;color: black;">Dear Sir/Mam, </h2>' +
			"<p style='font-size:14px;color: black;'>You have received an enquiry from the website. The details are as follows:</p>" +
			'<div class="row" style="width: 100%; display: flex">' +
			'<div class="col1" style="width: 30%;display:flex"><b style="color:black">Name</b></div><div style="text-align:center;width: 20%;">-</div>' +
			'<div class="col1" style="width: 50%">' +
			Enquiry.name +
			"</div>" +
			"</div>" +
			"<br>" +
			'<div class="row" style="width: 100%; display: flex">' +
			'<div class="col1" style="width: 30%;display:flex"><b style="color:black">Mobile Number</b></div><div style="text-align:center;width: 20%;">-</div>' +
			'<div class="col1" style="width: 50%">' +
			Enquiry.mobile +
			"</div>" +
			"</div>" +
			"<br>" +
			'<div class="row" style="width: 100%; display: flex">' +
			'<div class="col1" style="width: 30%; display: flex"><b style="color:black">Email</b></div><div style="text-align:center;width: 20%;">-</div>' +
			'<div class="col1" style="width: 50%">' +
			Enquiry.email +
			"</div>" +
			"</div>" +
			"<br>" +
			'<div class="row" style="width: 100%; display: flex">' +
			'<div class="col1" style="width: 30%; display: flex"><b style="color:black">Subject</b></div><div style="text-align:center;width: 20%;">-</div>' +
			'<div class="col1" style="width: 50%">' +
			Enquiry.subject +
			"</div>" +
			"</div>" +
			"<br>" +
			'<div class="row" style="width: 100%; display: flex">' +
			'<div class="col1" style="width: 30%; display: flex"><b style="color:black">Message</b></div><div style="text-align:center;width: 20%;">-</div>' +
			'<div class="col1" style="width: 50%">' +
			Enquiry.message +
			"</div>" +
			"</div>" +
			"<p style='color:black;padding-bottom: 20px;'>Thank You</p>" +
			"</div></div>" +
			"</body>" +
			"</html>"
		);
	}
	useEffect(() => {
		if (Rotatecaptcha == "") {
			setRotatecaptcha("iorotatevig");
		}
	}, [Rotatecaptcha]);
	const [captcha_number, setcaptcha_number] = useState("");
	useEffect(() => {
		setcaptcha_number(
			Math.floor(100000 + Math.random() * 900000)
				.toString(36)
			[Math.random() < 0.5 ? "toString" : "toUpperCase"]()
		);
	}, []);
	function Genratecaptcha() {
		var reloadcapcha = Math.floor(100000 + Math.random() * 900000)
			.toString(36)
		[Math.random() < 0.5 ? "toString" : "toUpperCase"]();
		setcaptcha_number(reloadcapcha);
		setRotatecaptcha(!Rotatecaptcha);
	}
	// notiflix message position
	useEffect(() => {
		Notiflix.Notify.Init({
			width: "280px",
			position: "center-bottom",
			distance: "10px",
			opacity: 1,
			clickToClose: true,
			showOnlyTheLastOne: true,
			//   useFontAwesome: true,
			fontSize: "15px",
			timeout: 4000,
		});
	}, []);
	useEffect(() => {
		setType(mailtypefetchurl.mailtype);
		setClientId(mailtypefetchurl.clientid);
	}, []);

	const submitEnquiry = () => {
		if (Enquiry.name != "") {
			if (
				Enquiry.email != ""
			) if (Enquiry.EmailRegex.test(Enquiry.email)) {
				{
					if (Enquiry.value != "") {
						if (Enquiry.value !== Enquiry.code) {
							if (
								Enquiry.captcha.toString() == captcha_number.toString()
							) {
								setLoader(true);
								const htmlMail = formateHtml();
								PostApiCall.postRequest(
									{
										mailtype: mailtype,
										mail: htmlMail,
										clientid: clientid,
										name: Enquiry.name,
										mobile: Enquiry.mobile,
										email: Enquiry.email,
										message: Enquiry.message,
									},
									"NewEnquiryMailerApi"
								).then((results2) =>
									results2.json().then((obj2) => {
										if (
											results2.status == 200 ||
											results2.status == 201
										) {
											setLoader(false);
											Notiflix.Notify.Success(
												"Thank you, our team will contact you shortly!"
											);
											setTimeout(() => {
												window.location.reload();
											}, 3000);
										}
									})
								);
							} else {
								Notiflix.Notify.Failure("Please enter valid captcha");
							}
						} else {
							Notiflix.Notify.Failure("Please enter mobile number");
						}

					} else {
						Notiflix.Notify.Failure("Please enter mobile number");
					}
				}
			} else {
					Notiflix.Notify.Failure("Please enter valid email");
				} else {
				Notiflix.Notify.Failure("Please enter email");
			}
		} else {
			Notiflix.Notify.Failure("Please enter name");
		}
	}

	const handleNumber = (value, country) => {
		setEnquiry(prevState => ({
			...prevState,
			mobile: "+" + value,
			value: value,
			code: country.dialCode,
		}));
	};
	return (
		<div className="container-fluid" style={{ background: "#f6f5f4", height: "100vh" }}>
			<div className="row">
				<div className="col-12">
					<div className=" newslatterform2 row">
						<div className="col-12">
							<input type="text" id="exampleFormControlInput1" name="name" value={Enquiry.name} onChange={(e) =>
								setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
							} placeholder="NAME/COMPANY" />
						</div>
						<div className="col-12">
							{/* <input type="text" id="exampleFormControlInput1" placeholder="MOBILE" /> */}
							<PhoneInput
								country={'in'}
								className="form-control tyrentyre-phone-box leadelan-phone-box"
								countryCodeEditable={false}
								// value={Enquiry.mobile}
								onChange={handleNumber}
								enableSearch={true}

							/>
						</div>
						<div className="col-12">
							<input type="email" id="exampleFormControlInput1" placeholder="EMAIL ADDRESS" value={Enquiry.email}
								onChange={(e) =>
									setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
								} name="email" />
						</div>
						<div className="col-12">
							<input type="text" id="exampleFormControlInput1" placeholder="SUBJECT" value={Enquiry.subject} onChange={(e) =>
								setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
							} name="subject" />
						</div>
						<div className="col-12">
							<input type="text" id="exampleFormControlInput1" placeholder="MESSAGE" value={Enquiry.message} onChange={(e) =>
								setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
							} name="message" />
						</div>
						<div className="col-md-6">
							<p
								className="px-2 py-2 my-2 w-100 me-3 border-dotted captcha-copy-disable manzil-captcha captchvaluesize d-flex justify-content-between align-items-center"
								style={{
									background: "#f6f5f4",
								}}
							>
								{captcha_number}
								<span
									onClick={() => {
										setRotatecaptcha("iorotatevig");
										Genratecaptcha();
									}}
									className={Rotatecaptcha == "iorotatevig" ? "iorotatevig" : ""}
								>
									<IoSync className="aButtonmanzil rotate" />
								</span>
							</p>
						</div>
						<div className="col-md-6">
							<input
								type="captcha"
								className="form-control my-2 captchvaluesize manzil-captcha w-100"
								placeholder="Enter Captcha *"
								name="captcha"
								value={Enquiry.captcha}
								onChange={(e) =>
									setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
								}
							/>
						</div>
						<div className="col-12">
							<button className="btn submit-btn mt-4" type="submit " onClick={submitEnquiry}>Submit</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LeadElanEnquiry